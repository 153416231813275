import React from 'react';
import Routes from './routes/Routes';


function App() { 
  var style;
  if(JSON.parse(localStorage.getItem('Authentication'))){
    style={
      background:'transparent linear-gradient(119deg, #CDDD3633 0%, #C421211A 24%, #499F4F1A 60%, #607D891A 81%, #DC32C01A 100%) 0% 0% no-repeat padding-box'
    } 
  }else{
    style={background:'none'}
  }
 
  return (
    
     <div className='App' style={style}>
        <Routes/>
    </div>
  );
}

export default App;