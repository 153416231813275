import React from 'react'
import { Navigate,useNavigate, useLocation } from 'react-router-dom'
import UnAuthorized from '../pages/UnAuthorized'

function PrivateRoutes({children}) {

  const location = useLocation();
  const navigate = useNavigate();
  var isAuthenticated = false;
  var isPasswordreset;
  var role_id;
  if("Authentication" in localStorage){
    isPasswordreset= JSON.parse(localStorage.getItem('Authentication')).is_password_reset;
    isAuthenticated = JSON.parse(localStorage.getItem('Authentication')).isAuthenticated;
    role_id = JSON.parse(localStorage.getItem('Authentication')).role_id;
  }
  
  React.useEffect(() => {
    if("Authentication" in localStorage){
      
      if (isAuthenticated ) {
        if(isPasswordreset === 1) {
          if(role_id === 2){
            return navigate('/admin/changepassword',{state:"Please change your password in order to continue using this application."});
          }
          else if(role_id === 3){
            return navigate('/branch-admin/changepassword',{state:"Please change your password in order to continue using this application."});
          }
          else if(role_id === 1){
            return navigate('/super-admin/changepassword',{state:"Please change your password in order to continue using this application."});
          }
          else{
            return navigate('/changepassword',{state:"Please change your password in order to continue using this application."});
          }
          }
        }
      }
      if("path" in localStorage){
        return navigate(((localStorage.getItem('path'))));
        
      }
    },[isAuthenticated,isPasswordreset,navigate,role_id]); 
    
    if("Authentication" in localStorage){
      isAuthenticated = JSON.parse(localStorage.getItem('Authentication')).isAuthenticated;
    
      if (isAuthenticated ) {  
        if(children.props.children.props.roleId.includes(role_id)){
          return children
        } 
        else {
          return <UnAuthorized/>;
        } 
        
      }
    }
  return <Navigate to={{
    pathname:"/",
    state:""
  }} state={{ from: location}} replace />

}

export default PrivateRoutes;