import * as React from 'react';
import { useNavigate } from 'react-router-dom'

export default function Notfound() {
  const navigate = useNavigate();

  const notfoundPage = {
    height:"100vh", display:"flex", alignItems:"center", justifyContent:"center"
  };
  const notfoundContent = {
    textAlign:"center", color:"#3D3D73"
  };
  const buttonstyle={
    textTransform: "capitalize",
    background: "#3D3D73 0% 0% no-repeat padding-box",
    borderRadius: "20px",
    width: "100%",
    height: "40px",
    color: "#FFFFFF",
    fontFamily: "Poppins",
    fontStyle: "normal",
    opacity: "1",
    cursor:"pointer"
  }

  const handleChange = () =>{
    localStorage.clear();
    window.location.reload();
    return navigate("/");
   

  }
  return (
      <div style={notfoundPage}>
        <div style={notfoundContent}>
        <h1>403</h1>
        <h2>UnAutorized Permission..</h2>
        <button onClick={handleChange} style={buttonstyle}>Go To Login Page</button>  
        </div>
      </div>
    );
}