import * as React from 'react';

export default function Notfound() {

  const notfoundPage = {
    height:"100vh", display:"flex", alignItems:"center", justifyContent:"center"
  };
  const notfoundContent = {
    textAlign:"center", color:"#3D3D73"
  };
  return (
      <div style={notfoundPage}>
        <div>
        <h1 style={notfoundContent}>404</h1>
        <h2 style={notfoundContent}>Page Not Found..</h2>
         </div>
      </div>
    );
}