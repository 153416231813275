import React from 'react'
import { Navigate } from 'react-router-dom'

function PublicRoutes({ children}) {
 
  const authentication = JSON.parse(localStorage.getItem('Authentication'));
  if(!authentication){
    return children
  }else{
    const role_id =JSON.parse(localStorage.getItem('Authentication')).role_id;
    if(role_id === 2){
      return <Navigate to="/admin/dashboard" />
    }
    else if(role_id=== 3){
      return <Navigate to="/branch-admin/dashboard" />
    }
    else if (role_id=== 1){
      return <Navigate to="/super-admin/dashboard" />
    }
    else{
      return <Navigate to="/select-school" />
    }
    
  }

}

export default PublicRoutes;
