import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";
import PrivateRoutes from './PrivateRoute';
import PublicRoutes from './PublicRoutes';
import Notfound from '../pages/NotFound';


const LoginPage = React.lazy(() => import("../pages/Login"));
const ForgotPassword = React.lazy(() => import("../pages/ForgotPassword"));
const DashboardPage = React.lazy(() => import("../pages/Dashboard/Dashboard"));
const ChangePassword = React.lazy(() => import("../pages/Dashboard/ChangePassword"));
const SelectSchool = React.lazy(() => import("../pages/User/SelectSchool"));
const StudentCard = React.lazy(() => import('../pages/User/Details/StudentCard'));
const StartExam = React.lazy(() => import('../pages/User/TestPage/StartExam'));
const Modules = React.lazy(() => import("../pages/Modules/Modules"));
const AddQuestionsPage = React.lazy(() => import('../pages/Modules/AddQuestionsPage'));
const ViewPage = React.lazy(() => import('../pages/Modules/ViewPage'));
const PresurveyExam = React.lazy(() => import('../pages/User/ProceedExam/PresurveyExam'));
const Loader = React.lazy(()=>import("../components/Loader/Loader"));
const SurveyPage = React.lazy(()=>import("../pages/Survey/Survey/Survey"))
const MasterData = React.lazy(()=>import("../pages/MasterData/Masterdata"));
const SchoolData = React.lazy(()=>import("../pages/MasterData/School/Schooldata"));
const Reports = React.lazy(()=>import("../pages/Reports/Report"));
const Users = React.lazy(()=>import("../pages/Dashboard/User"));
const EndExam = React.lazy(()=>import("../pages/User/EndExam/EndExams"));
const StudentID = React.lazy(()=>import("../pages/User/StudentID/StudentIds"));
const SurveyList = React.lazy(()=>import("../pages/Survey/SurveyList/SurveyList"));

function Routers() {
  return (
    <BrowserRouter>
      <Routes>


        {/* PublicRoutes Start */}
        <Route
          path="/"
          element={
            <PublicRoutes>
              <Suspense fallback={<Loader />}>
                <LoginPage />
              </Suspense>
            </PublicRoutes>
          }
        />
        <Route
          path="/forgot-password"
          element={
            <PublicRoutes>
              <Suspense fallback={<Loader />}>
                <ForgotPassword />
              </Suspense>
            </PublicRoutes>
          }
        />
        {/* PublicRoutes End */}

        {/* PrivateRoutes Start */}
        {/* Admin Routes Start  */}
        <Route path="admin">
          <Route
            path="dashboard"
            element={
              <PrivateRoutes>
                <Suspense fallback={<Loader />}>
                  <DashboardPage roleId={[2]} />
                </Suspense>
              </PrivateRoutes>
            }
          />
          <Route
            path="modules"
            element={
              <PrivateRoutes>
                <Suspense fallback={<Loader />}>
                  <Modules roleId={[2]} />
                </Suspense>
              </PrivateRoutes>
            }
          />
          <Route
            path="modules/add-questions"
            element={
              <PrivateRoutes>
                <Suspense fallback={<Loader />}>
                  <AddQuestionsPage roleId={[2]} />
                </Suspense>
              </PrivateRoutes>
            }
          />

          <Route
            path="modules/edit-questions"
            element={
              <PrivateRoutes>
                <Suspense fallback={<Loader />}>
                  <AddQuestionsPage roleId={[2]} />
                </Suspense>
              </PrivateRoutes>
            }
          />

          <Route
            path="modules/view"
            element={
              <PrivateRoutes>
                <Suspense fallback={<Loader />}>
                  <ViewPage roleId={[2]} />
                </Suspense>
              </PrivateRoutes>
            }
          />
          <Route
            path="changepassword"
            element={
              <PrivateRoutes>
                <Suspense fallback={<Loader />}>
                  <ChangePassword roleId={[2]} />
                </Suspense>
              </PrivateRoutes>
            }
          />
          <Route
            path="users"
            element={
              <PrivateRoutes>
                <Suspense fallback={<Loader />}>
                  <Users roleId={[2]} />
                </Suspense>
              </PrivateRoutes>
            }
          />
          <Route
            path="masterdata"
            element={
              <PrivateRoutes>
                <Suspense fallback={<Loader />}>
                  <MasterData roleId={[2]} />
                </Suspense>
              </PrivateRoutes>
            }
          />
          <Route
            path="masterdata/school"
            element={
              <PrivateRoutes>
                <Suspense fallback={<Loader />}>
                  <SchoolData roleId={[2]} />
                </Suspense>
              </PrivateRoutes>
            }
          />
          <Route
            path="reports"
            element={
              <PrivateRoutes>
                <Suspense fallback={<Loader />}>
                  <Reports roleId={[2]} />
                </Suspense>
              </PrivateRoutes>
            }
          />
          {/* Admin Routes End  */}
          {/* PrivateRoutes End */}

        </Route>
            {/* PublicRoutes Start */}
            <Route 
                path="/" 
                element={
                  <PublicRoutes>
                    <Suspense fallback={<Loader/>}>
                        <LoginPage />
                    </Suspense>
                  </PublicRoutes>
                } 
            /> 
            <Route 
                path="/forgot-password" 
                element={
                  <PublicRoutes>
                    <Suspense fallback={<Loader/>}>
                        <ForgotPassword />
                    </Suspense>
                  </PublicRoutes>
                } 
            /> 
            {/* PublicRoutes End */}

            {/* PrivateRoutes Start */}

              {/* User Routes Start */}
              <Route 
                  path="/select-school" 
                  element={
                    <PrivateRoutes>
                      <Suspense fallback={<Loader/>}>
                        <SelectSchool roleId={[4]}/>
                      </Suspense>
                    </PrivateRoutes>
                  } 
              /> 
               <Route 
                  path="/student-card" 
                  element={
                    <PrivateRoutes>
                      <Suspense fallback={<Loader/>}>
                        <StudentCard roleId={[4]}/>
                      </Suspense>
                    </PrivateRoutes>
                  } 
              /> 
              <Route 
                  path="/start-exam" 
                  element={
                    <PrivateRoutes>
                      <Suspense fallback={<Loader/>}>
                        <StartExam roleId={[4]}/>
                      </Suspense>
                    </PrivateRoutes>
                  } 
              /> 
              <Route 
                  path="/presurvey-exam" 
                  element={
                    <PrivateRoutes>
                      <Suspense fallback={<Loader/>}>
                        <PresurveyExam roleId={[4]} />
                      </Suspense>
                    </PrivateRoutes>
                  } 
              /> 
              <Route 
                  path="/endexam" 
                  element={
                    <PrivateRoutes>
                      <Suspense fallback={<Loader/>}>
                        <EndExam roleId={[4]} />
                      </Suspense>
                    </PrivateRoutes>
                  } 
              />
              <Route 
                  path="/studentid" 
                  element={
                    <PrivateRoutes>
                      <Suspense fallback={<Loader/>}>
                        <StudentID roleId={[4]} />
                      </Suspense>
                    </PrivateRoutes>
                  } 
              />
              <Route
                path="changepassword"
                element={
                  <PrivateRoutes>
                    <Suspense fallback={<Loader />}>
                      <ChangePassword roleId={[4]} />
                    </Suspense>
                  </PrivateRoutes>
                }
              />
              
              {/* User Routes End  */}
              
              {/* Branch-Admin Routes Start  */}
              <Route path="branch-admin">
                <Route 
                    path="survey" 
                    element={
                      <PrivateRoutes>
                        <Suspense fallback={<Loader/>}>
                          <SurveyPage roleId={[3]}/>
                        </Suspense>
                      </PrivateRoutes>
                    } 
                />
                <Route
                    path="dashboard"
                    element={
                      <PrivateRoutes>
                        <Suspense fallback={<Loader />}>
                          <DashboardPage roleId={[3]} />
                        </Suspense>
                      </PrivateRoutes>
                    }
                />
                <Route 
                    path="survey/List" 
                    element={
                      <PrivateRoutes>
                        <Suspense fallback={<Loader/>}>
                          <SurveyList roleId={[3]}/>
                        </Suspense>
                      </PrivateRoutes>
                    } 
                />
                <Route 
                    path="masterdata/school" 
                    element={
                      <PrivateRoutes>
                        <Suspense fallback={<Loader/>}>
                          <SchoolData roleId={[3]}/>
                        </Suspense>
                      </PrivateRoutes>
                    }
                    />
                  <Route
                    path="reports"
                    element={
                      <PrivateRoutes>
                        <Suspense fallback={<Loader />}>
                          <Reports roleId={[3]} />
                        </Suspense>
                      </PrivateRoutes>
                  }
                  />
                  <Route
                    path="changepassword"
                    element={
                      <PrivateRoutes>
                        <Suspense fallback={<Loader />}>
                          <ChangePassword roleId={[3]} />
                        </Suspense>
                      </PrivateRoutes>
                    }
                  />

              </Route>
              {/* Branch-Admin Routes End  */}

              {/* Super-Admin Routes Start  */}
              <Route path="super-admin">
                <Route
                    path="dashboard"
                    element={
                      <PrivateRoutes>
                        <Suspense fallback={<Loader />}>
                          <DashboardPage roleId={[1]} />
                        </Suspense>
                      </PrivateRoutes>
                    }
                />
                <Route
                    path="reports"
                    element={
                      <PrivateRoutes>
                        <Suspense fallback={<Loader />}>
                          <Reports roleId={[1]} />
                        </Suspense>
                      </PrivateRoutes>
                  }
                />
                <Route
                    path="changepassword"
                    element={
                      <PrivateRoutes>
                        <Suspense fallback={<Loader />}>
                          <ChangePassword roleId={[1]} />
                        </Suspense>
                      </PrivateRoutes>
                    }
                  />
              </Route>
              <Route path="/*" element={<Notfound />} />


              {/* Super-Admin Routes End  */}

        </Routes>
    </BrowserRouter>
  );
}

export default Routers;